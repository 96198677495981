




import { Component, Mixins } from 'vue-property-decorator'

import PermissionsMixin from '@/mixins/PermissionsMixin'

@Component
export default class Education extends Mixins(PermissionsMixin) {
  private mounted () {
    if (this.$route.name === 'manager.education') {
      if (this.hasSomePermissions([this.Permission.EDUCATION_EXERCISES_VIEW, this.Permission.EDUCATION_EXERCISES_CHECK])) {
        this.$router.replace({ name: 'manager.education.exercises' })
      } else if (this.hasPermission(this.Permission.EDUCATION_LESSON_LIST)) {
        this.$router.replace({ name: 'manager.education.lessons' })
      } else if (this.hasPermission(this.Permission.MASTER_VIEW_ALL)) {
        this.$router.replace({ name: 'manager.education.masters' })
      } else if (this.hasSomePermissions([this.Permission.MENTOR_LIST_ALL, this.Permission.MENTOR_LIST_VIEW])) {
        this.$router.replace({ name: 'manager.education.mentors' })
      } else {
        this.$router.replace({ name: 'manager' })
      }
    }
  }
}
